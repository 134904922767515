#a2apage_dropdown {
  transform: translate(0, 10px);
  padding: 10px;
}

.a2apage_wide {
  border-top: 1px solid #EEE;
  margin-top: 15px !important;
  padding-top: 10px !important;
}

#a2apage_show_more_less {
  border: none !important;
}
