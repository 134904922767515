html,
body,
#__next {
  min-height: 100vh;
}

#gallery {
  max-height: min(700px, 70vh);
}

div#3ds-iframe-container iframe::backdrop {
  background-color: rgba(0, 0, 0, 0.25);
}

#checkout iframe {
  width: 100% !important;
  max-width: 100% !important;
}